<template>
  <div>
    <v-form
      v-model="validation"
      ref="form"
      @submit.prevent="title === 'Crear' ? createPatient() : editPatient()"
    >
      <v-container>
        <Title :title="title + ' paciente'" class="mb-8" />
        <v-row justify="start" class="mb-5">
          <v-chip
            title="Datos generales"
            class="ma-2"
            :color="generalDataComplete ? 'success' : 'disable'"
            label
          >
            <v-icon left small> mdi-account </v-icon>
            Datos generales
          </v-chip>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.firstName"
              type="text"
              label="Nombres *"
              :rules="rules.default"
              @keypress="capitalizeWords()"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.lastName"
              type="text"
              label="Apellidos *"
              :rules="rules.default"
              @keydown="capitalizeWords()"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.identificationType"
              :items="identificationTypes"
              label="Tipo de identificación"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.identificationDocument"
              type="text"
              label="No. identificación"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.gender"
              :items="genders"
              label="Sexo biológico"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Fecha de nacimiento"
              placeholder="DD/MM/AAAA"
              v-model="form.bornDate"
              :hint="age"
              persistent-hint
              outlined
              @input="formatDate"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-combobox
              v-model="form.profession"
              :items="professions"
              label="Profesión/oficio"
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.bloodType"
              :items="bloodTypes"
              label="Grupo sanguíneo"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row justify="start" class="mb-5">
          <v-chip
            title="Datos de contacto"
            class="ma-2"
            :color="contactDataComplete ? 'success' : 'disable'"
            label
          >
            <v-icon left small> mdi-phone </v-icon>
            Contacto
          </v-chip>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.phone"
              type="text"
              label="Teléfono"
              @wheel="$event.target.blur()"
              hint="809-000-0000"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.emergencyNumber"
              type="text"
              @wheel="$event.target.blur()"
              label="Teléfono para emergencias"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.email"
              type="text"
              label="Correo electrónico"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row justify="start" class="mb-5">
          <v-chip
            title="Datos de ubicación"
            class="ma-2"
            :color="locationDataComplete ? 'success' : 'disable'"
            label
          >
            <v-icon left small> mdi-map-marker </v-icon>
            Ubicación
          </v-chip>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-combobox
              v-model="form.province"
              :items="provinces"
              label="Provincia"
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.address"
              type="text"
              label="Dirección"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row justify="start" class="mb-5">
          <v-chip
            title="Datos de seguridad médica"
            class="ma-2"
            :color="healthSecurityDataComplete ? 'success' : 'disable'"
            label
          >
            <v-icon left small> mdi-clipboard-pulse </v-icon>
            Seguro médico
          </v-chip>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.ARS"
              :items="currentUser.doctor.ars"
              item-text="description"
              item-value="id"
              label="Aseguradora"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.ARSNumber"
              type="text"
              label="No. afiliado"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.NSS"
              type="text"
              label="NSS"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        depressed
        small
        color="primary"
        class="pa-5"
        type="submit"
        :disabled="!validation"
        :loading="loading"
      >
        {{ title }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { calculateAge } from "@/helpers/common.js";
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import Title from "../Title.vue";
import moment from "moment";

export default {
  name: "PatientForm",
  components: {
    Title,
  },
  props: ["title", "patient"],
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        bornDate: "",
        gender: "",
        emergencyNumber: "",
        address: "",
        province: "",
        profession: "",
        email: "",
        identificationDocument: "",
        identificationType: "",
        NSS: "",
        ARSNumber: "",
        ARS: null,
        bloodType: "",
      },
      oldValueID: "",
      oldValPassPort: "",
      genders: [
        { text: "Masculino", value: "M" },
        { text: "Femenino", value: "F" },
        { text: "Sin definir", value: "" },
      ],
      bloodTypes: [
        { text: "A+", value: "A+" },
        { text: "A-", value: "A-" },
        { text: "B+", value: "B+" },
        { text: "B-", value: "B-" },
        { text: "O+", value: "O+" },
        { text: "O-", value: "O-" },
        { text: "AB+", value: "AB+" },
        { text: "AB-", value: "AB-" },
      ],
      identificationTypes: [
        { text: "Cédula", value: "Cédula" },
        { text: "Pasaporte", value: "Pasaporte" },
        { text: "Indocumentado", value: "Indocumentado" },
      ],
      dateMenu: false,
      menu: false,
      activePicker: null,
      rules: {
        default: [
          (v) => !!v || "Campo obligatorio",
          (v) => (v && v.length <= 50) || "Debe tener menos de 50 caracteres",
        ],
      },
      validation: true,
      loading: false,
      pickerDate: "1995-01-01",
    };
  },
  computed: {
    ...mapGetters(["currentUser", "provinces", "professions"]),
    generalDataComplete() {
      return (
        this.form.firstName &&
        this.form.lastName &&
        this.form.gender &&
        this.form.identificationDocument &&
        this.form.profession &&
        this.form.bloodType &&
        this.form.bornDate
      );
    },
    contactDataComplete() {
      return this.form.phone && this.form.email && this.form.emergencyNumber;
    },
    locationDataComplete() {
      return this.form.province && this.form.address;
    },
    healthSecurityDataComplete() {
      return this.form.ARS && this.form.NSS && this.form.ARSNumber;
    },
    age() {
      return this.form.bornDate
        ? calculateAge(
            moment(this.form.bornDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          )
        : "";
    },
  },
  methods: {
    ...mapMutations([
      "pushPatient",
      "setAlert",
      "updateAppointmentPatient",
      "updatePatient",
    ]),
    validateForm() {
      const dateRegex =
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
      const isValidBornDate = this.form.bornDate
        ? dateRegex.test(this.form.bornDate)
        : true;
      if (!isValidBornDate) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: "Formato de fecha no válida (día/mes/año)",
        });
        return false;
      }
      return true;
    },
    async createPatient() {
      const isValid = this.validateForm();
      if (!isValid) {
        return false;
      }

      this.loading = true;
      try {
        const res = await axios.post("/api/patient", {
          ...this.form,
          bornDate: this.form.bornDate
            ? moment(this.form.bornDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          doctor: this.currentUser.doctor.id,
          ARS: this.currentUser.doctor.ars.filter(
            (a) => this.form.ARS === a.id
          )[0],
        });
        const { patient, message } = res.data;
        this.pushPatient(patient);
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$refs.form.reset();
        this.$emit("patient-created", patient);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    async editPatient() {
      const isValid = this.validateForm();
      if (!isValid) {
        return false;
      }
      this.loading = true;
      try {
        const res = await axios.put(`/api/patient/${this.patient.id}`, {
          id: this.patient.id,
          ...this.form,
          bornDate: this.form.bornDate
            ? moment(this.form.bornDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          ARS: this.currentUser.doctor.ars.filter(
            (a) => this.form.ARS === a.id
          )[0],
        });
        const { patient, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.updateAppointmentPatient(patient);
        this.updatePatient(patient);
        this.$emit("patient-updated", patient);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("on-cancel");
    },
    capitalizeWords() {
      this.form.firstName = this.form.firstName.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
      );
      this.form.lastName = this.form.lastName.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
      );
    },
    // save born date
    save(date) {
      this.$refs.menu.save(date);
      this.pickerDate = date;
    },
    createUndocumentCode() {
      const firstName = this.form.firstName;
      const lastName = this.form.lastName;
      const bornDate = this.form.bornDate; // Expected format: "DD/MM/YYYY"

      if (
        firstName !== "" &&
        lastName !== "" &&
        bornDate !== "" &&
        bornDate.includes("/")
      ) {
        const day = bornDate.substring(0, 2); // Extract "DD"
        const month = bornDate.substring(3, 5); // Extract "MM"
        const year = bornDate.substring(8, 10); // Extract last two digits of "YYYY"

        return `${firstName[0]}${lastName[0]}${year}${month}${day}`;
      } else {
        return "";
      }
    },
    setUndocumentCode() {
      this.form.identificationDocument = this.createUndocumentCode();
    },
    formatDate() {
      let value = this.form.bornDate.replace(/\D/g, ""); // Remove non-numeric characters

      if (value.length > 2) value = `${value.slice(0, 2)}/${value.slice(2)}`;
      if (value.length > 5) value = `${value.slice(0, 5)}/${value.slice(5, 9)}`;

      this.form.bornDate = value;
    },
  },
  watch: {
    patient: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          Object.assign(this.form, newVal);
          this.form.bornDate = newVal.bornDate
            ? moment(newVal.bornDate, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "";
        }

        if (this.title === "Crear") {
          this.$refs.form?.reset();
        }
      },
    },
    "form.phone": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.form.phone = newVal.replace(/[^\d-]/g, "");
        }
      },
    },
    "form.identificationType": function (val) {
      if (val === "Indocumentado") {
        this.setUndocumentCode();
      }
    },
    "form.firstName": function () {
      if (this.form.identificationType === "Indocumentado") {
        this.setUndocumentCode();
      }
    },
    "form.lastName": function () {
      if (this.form.identificationType === "Indocumentado") {
        this.setUndocumentCode();
      }
    },
    "form.bornDate": function () {
      if (this.form.identificationType === "Indocumentado") {
        this.setUndocumentCode();
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
